<template>
  <div class="flex-start space-x-12">
    <account-name class="font-bold" :account="account" noReload />
    <div class="text-sm">
      Balance: {{ account.balance | formatNumber }}
      <br />
      Equity: {{ account.equity | formatNumber }}
    </div>
    <account-position :account="account" />
    <div class="flex-between space-x-1">
      <account-chart
        label="Daily"
        :account="account"
        type="daily"
        :width="120"
        :height="120"
      />
      <account-chart
        label="Weekly"
        class=""
        :account="account"
        type="weekly"
        :width="120"
        :height="120"
      />
      <account-chart
        label="Monthly"
        :account="account"
        type="monthly"
        :width="120"
        :height="120"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AccountName from '@/components/apps/accounts/group-card/AccountName.vue'
import AccountPosition from '@/components/apps/accounts/group-card/Position.vue'
import AccountChart from '@/components/apps/accounts/group-card/Chart.vue'

export default {
  name: 'account-balance',

  components: {
    AccountName,
    AccountChart,
    AccountPosition,
  },

  props: {
    accountId: String,
  },

  computed: {
    ...mapGetters({
      accountMap: 'credentials/accountMap',
    }),
    line() {
      const data = this.account.chart?.daily || '0'
      return `https://chart.googleapis.com/chart?chd=t:${data}&cht=lc&chs=100x100&chco=166913&chds=a`
    },
    account() {
      return this.accountMap[this.accountId]
    },
  },
}
</script>
