<template>
  <div>
    <d-btn color="info" size="xs" @click.stop="entry"> Entry </d-btn>
    <d-btn color="error" size="xs" @click.stop="cancel"> Cancel </d-btn>
  </div>
</template>

<script>
export default {
  name: 'ActiveButtons',

  props: {
    directOrderId: String,
  },

  methods: {
    entry() {
      this.directOrderId &&
        this.$$confirm({
          title: 'Notice',
          text: 'Do you want to entry right away?',
          confirmText: 'Yes',
          confirmColor: 'warning',
          onConfirm: async () => {
            await this.$http.post('/api/v2/manual-orders/entry', {
              id: this.directOrderId,
            })
          },
        })
    },
    cancel() {
      this.directOrderId &&
        this.$$confirm({
          title: 'Notice',
          text: 'Do you want to cancel the order?',
          confirmText: 'Yes',
          confirmColor: 'warning',
          onConfirm: async () => {
            await this.$http.post('/api/v2/manual-orders/cancel', {
              id: this.directOrderId,
            })
            this.$emit('onReload')
          },
        })
    },
  },
}
</script>
