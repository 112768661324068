<template>
  <div class="flex flex-col gap-1">
    <div class="badge badge-md badge-success">{{ open | formatNumber }}</div>
    <div class="badge badge-md badge-error" v-if="close">
      {{ close | formatNumber }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'badge-two-side-price',

  props: {
    open: {
      type: [String, Number],
    },
    close: {
      type: [String, Number],
    },
  },
}
</script>
