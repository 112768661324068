<template>
  <div>
    <d-btn :disabled="!isOpen" color="warning" size="xs" @click.stop="close">
      {{ $t('Revert') }}
    </d-btn>
    <d-btn :disabled="!isOpen" color="primary" size="xs" @click.stop="cancel">
      {{ $t('Clear') }}
    </d-btn>
  </div>
</template>

<script>
import { ORDER_STATUS } from '@/common/constants.js'
import UpdateManualOrderModal from '@/components/apps/orders/update-manual-order-modal/index.vue'

export default {
  name: 'ActionButtons',

  props: {
    order: Object,
  },

  computed: {
    isOpen() {
      return this.order.status === ORDER_STATUS.OPEN
    },
  },

  methods: {
    close() {
      if (!this.isOpen) {
        return
      }
      if (this.order.directOrder) {
        return this.$$showModal({
          component: UpdateManualOrderModal,
          props: {
            order: this.order,
          },
        })
      }
      this.$$confirm({
        title: 'Notice',
        text: 'Do you want to close right away?',
        confirmText: 'Yes',
        confirmColor: 'warning',
        onConfirm: async () => {
          await this.$http.post('/api/v2/logic-orders/close', {
            id: this.order._id,
          })
          this.$emit('onReload')
        },
      })
    },
    cancel() {
      this.isOpen &&
        this.$$confirm({
          title: 'Notice',
          text: 'Do you want to cancel the order?',
          confirmText: 'Yes',
          confirmColor: 'warning',
          onConfirm: async () => {
            await this.$http.post('/api/v2/logic-orders/cancel', {
              id: this.order._id,
            })
            this.$emit('onReload')
          },
        })
    },
  },
}
</script>
