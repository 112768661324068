<template>
  <div>
    <div class="font-bold text-lg">Update / Close Order</div>

    <div class="mt-4 w-full flex flex-col gap-2">
      <div class="flex gap-4">
        <label class="cursor-pointer label"> TP </label>
        <input type="number" class="input-box" v-model="tp" />
      </div>
      <div class="flex gap-4">
        <label class="cursor-pointer label"> SL </label>
        <input type="number" class="input-box" v-model="sl" />
      </div>
    </div>
    <div class="flex mt-4 w-full justify-end gap-2">
      <d-btn color="info" @click="update">{{ $t('Update') }}</d-btn>
      <d-btn color="warning" @click="close">{{ $t('Revert') }}</d-btn>
      <d-btn color="" @click="cancel">{{ $t('Cancel') }}</d-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateManualOrdermodal',

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tp: 0,
    sl: 0,
  }),

  mounted() {
    this.tp = this.order.tp || 0
    this.sl = this.order.sl || 0
  },

  methods: {
    async cancel(event) {
      this.$$closeModal(event)
    },
    async close(event) {
      await this.$http.post('/api/v2/logic-orders/close', {
        id: this.order._id,
      })
      this.$emit('onReload')
      this.$$closeModal(event)
    },
    async update(event) {
      this.order.tp = this.tp
      this.order.sl = this.sl
      await this.$http.post('/api/v2/manual-orders/update', {
        id: this.order.directOrder,
        tp: this.tp,
        sl: this.sl,
      })
      this.$emit('onReload')
      this.$$closeModal(event)
    },
  },
}
</script>
