import { ORDER_STATUS, EXCHANGE_NAMES } from '@/common/constants.js'
import { formatNumber } from '@/utils/string'
import BadgeOrderSide from '@/components/apps/badges/badge-order-side/index.vue'
import BadgeTwoSidePrice from '@/components/apps/badges/badge-two-side-price/index.vue'
import BadgeTwoSideTimestamp from '@/components/apps/badges/badge-two-side-timestamp/index.vue'
import BadgeTwoSideOrderId from '@/components/apps/badges/badge-two-side-order-id/index.vue'
import Cryptofonts from '@/components/daisy/atoms/cryptofonts/index.vue'
import ActiveButtons from './ActiveButtons.vue'
import ActionButtons from './ActionButtons.vue'
import Profit from './Profit.vue'

function _headerItem(title, key, ext) {
  return {
    title,
    key,
    ...ext,
  }
}

export default function headers(_this) {
  const isActive = _this.isActive
  return [
    global.IS_DEBUG
      ? _headerItem('ID', '_id', {
        generator: (item) => {
          return { value: item._id }
        },
      })
      : null,
    _headerItem('Logic Name', 'name', {
      cellClass: 'sticky left-0 font-semibold',
      class: 'lg:w-48 w-32 overflow-hidden',
      generator: (order) => {
        let value = ''
        if (order.internalNote?.auto) {
          value = order.internalNote?.name || '---'
        } else {
          value = value || _this.logicById[order.logic]?.name || '---'
        }
        if (order.status === ORDER_STATUS.CANCELLED) {
          value = `${value} (CANCELLED)`
        }
        return { value }
      },
    }),
    _headerItem('Account', 'account', {
      cellClass: 'text-xs font-semibold',
      generator: (item) => {
        const account = _this.accountById?.[item.credential]
        if (account?.productId && item.exchange === 'bitcopy') {
          return { value: `${account?.productId} (bitcopy)` }
        }
        return { value: `
          <div>
            ${account?.name || '-'}
            <br/>
            (${EXCHANGE_NAMES[item?.exchange] || '-'})
          </div>` }
      },
    }),
    _headerItem('Date', 'timestamp', {
      generator: (item) => {
        return {
          component: BadgeTwoSideTimestamp,
          props: {
            open: item.entry?.timestamp,
            close: item.close?.timestamp,
          },
        }
      },
    }),
    isActive
      ? null
      : _headerItem('Order ID', 'orderId', {
        generator: (item) => {
          return {
            component: BadgeTwoSideOrderId,
            props: {
              open: item.entry?.orderId,
              close: item.close?.orderId,
            },
          }
        },
      }),
    _headerItem('Symbol', 'symbol', {
      generator: (item) => {
        return {
          component: Cryptofonts,
          props: { symbol: item.symbol },
        }
      },
    }),
    _headerItem('Side', 'side', {
      cellClass: 'font-semibold',
      generator: (order) => ({
        component: BadgeOrderSide,
        props: { order },
      }),
    }),
    isActive
      ? _headerItem('Mark Price', 'markPrice', {
        cellClass: 'font-semibold',
        generator: (item) => ({
          component: BadgeTwoSidePrice,
          props: {
            open: parseFloat(item?.memo?.replace('mark price: ', '') || 0),
          },
        }),
      })
      : _headerItem('Price', 'price', {
        cellClass: 'font-semibold',
        generator: (item) => ({
          component: BadgeTwoSidePrice,
          props: {
            open: item.entry?.price,
            close: item.close?.price,
          },
        }),
      }),
    _headerItem('Volume', 'volume', {
      cellClass: 'font-semibold',
      generator: (item) => ({
        value: formatNumber(item.volume),
      }),
    }),
    _headerItem('Tp', 'tp', {
      cellClass: 'font-semibold',
      generator: (item) => ({
        value: formatNumber(item.tp),
      }),
    }),
    _headerItem('Sl', 'sl', {
      cellClass: 'font-semibold',
      generator: (item) => ({
        value: formatNumber(item.sl),
      }),
    }),
    isActive
      ? null
      : _headerItem('Profit', 'profit', {
        cellClass: 'font-semibold',
        generator: (item) => ({
          component: Profit,
          props: { order: item },
        }),
      }),
    isActive
      ? _headerItem('Actions', 'actions', {
        generator: (item) => {
          return {
            component: ActiveButtons,
            props: {
              directOrderId: item.directOrder || '',
            },
            events: {
              onReload: _this._loadData,
            },
          }
        },
      })
      : _headerItem('Actions', 'actions', {
        generator: (order) => {
          return {
            component: ActionButtons,
            props: {
              order,
            },
            events: {
              onReload: _this._loadData,
            },
          }
        },
      }),
  ].filter(Boolean)
}
