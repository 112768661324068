<template>
  <div :class="color">${{ profit | formatNumber }}</div>
</template>

<script>
import { mapGetters } from 'vuex'

const BUY = 1

const symbols = {
  BTCUSD_PERP: 'BTCUSDT',
  ETHUSD_PERP: 'ETHUSDT',
  BTC_USDT_SWAP: 'BTCUSDT',
  ETH_USDT_SWAP: 'ETHUSDT',
  LTC_USDT_SWAP: 'LTCUSDT',
  XRP_USDT_SWAP: 'XRPUSDT',
  SOL_USDT_SWAP: 'SOLUSDT',
  DOGE_USDT_SWAP: 'DOGEUSDT',
  AAVE_USDT_SWAP: 'AAVEUSDT',
  ATOM_USDT_SWAP: 'ATOMUSDT',
  MATIC_USDT_SWAP: 'MATICUSDT',
  TRX_USDT_SWAP: 'TRXUSDT',
}

export default {
  name: 'Profit',

  props: {
    order: Object,
  },

  computed: {
    ...mapGetters({
      marketPrices: 'market/marketPrices',
    }),
    color() {
      if (this.profit === 0) {
        return ''
      }
      return this.profit < 0 ? 'text-error' : 'text-success'
    },
    profit() {
      const entryPrice = this.order?.entry?.price || 0
      if (!entryPrice) {
        return 0
      }
      const symbol = symbols[this.order.symbol] || this.order.symbol || ''
      const closePrice =
        this.order?.close?.price || this.marketPrices[symbol] || 0
      if (!closePrice) {
        return 0
      }
      const volume = this.order.volume
      const k = this.order?.entry?.side === BUY ? 1 : -1
      const diff = k * (closePrice - entryPrice)

      if (isFiatBase(this.order.exchange)) {
        return _round(diff * volume)
      }

      if (isCoinBase(this.order.exchange)) {
        return _round((diff * volume) / entryPrice)
      }
      if (volume < 1) {
        return _round(diff * volume)
      }
      return _round((diff * volume) / entryPrice)
    },
  },
}

function _round(value, digit = 10) {
  return Math.round(value * digit) / digit
}

const EXCHANGES = {
  PAPER: 'paper',
  BYBIT_COPY: 'bybit_copy',
  BINANCE_COIN_M: 'binance_coin_m',
  BINANCE_COIN_S: 'binance_coin_s',
  CCI: 'cci',
  GROUP: 'group',
  BITCOPY: 'bitcopy',
}

const BASE = {
  [EXCHANGES.PAPER]: 'coin',
  [EXCHANGES.BYBIT_COPY]: 'fiat',
  [EXCHANGES.BINANCE_COIN_M]: 'coin',
  [EXCHANGES.BINANCE_COIN_S]: 'fiat',
  [EXCHANGES.CCI]: 'coin',
  [EXCHANGES.OMTRADER]: 'fiat',
  [EXCHANGES.BITCOPY]: 'coin',
}

export function isCoinBase(exchange) {
  return BASE[exchange] === 'coin'
}

export function isFiatBase(exchange) {
  return BASE[exchange] === 'fiat'
}
</script>
