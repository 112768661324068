<template>
  <div class="flex flex-col gap-1">
    <div class="badge badge-md badge-success">{{ open | formatOrderId }}</div>
    <div class="badge badge-md badge-error" v-if="close">
      {{ close | formatOrderId }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'badge-two-side-order-id',

  props: {
    open: {
      type: String,
    },
    close: {
      type: String,
    },
  },
}
</script>
