<template>
  <div>
    <div
      v-for="(position, index) in positions"
      class="w-full flex items-center mb-1 justify-start space-x-2 overflow-hidden"
      :key="index"
    >
      <cryptofonts
        imageOnly
        class="w-6"
        :symbol="position.symbol || 'BTCUSD'"
      />
      <span>
        {{ size(position) }}
        <template v-if="position.liqPrice">
          <br />
          <i class="text-xs"> Liq: {{ position.liqPrice | formatNumber }} </i>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/utils/string'
import { positionSize } from '@/utils/exchange'

export default {
  name: 'account-positions',

  props: {
    account: Object,
  },

  computed: {
    positions() {
      return (this.account.positions || []).filter((el) => el.volume > 0)
    },
  },

  methods: {
    size(position) {
      let size = positionSize(position)
      if (Math.abs(size) > 1e5) {
        size = Math.round(size / 1e4) / 100
        return `${formatNumber(size)}m`
      }
      return position ? formatNumber(size) : '--'
    },
  },
}
</script>
