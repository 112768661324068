<template>
  <div class="badge" :class="classes" v-tooltip="tooltip" @click.stop="onClick">
    {{ text }}
    <template v-if="tooltip">
      &nbsp;
      <span class="text-red-500"> ⚠ </span>
    </template>
  </div>
</template>

<script>
import { ORDER_SIDES } from '@/common/constants.js'

export default {
  name: 'badge-order-side',

  props: {
    order: Object,
  },

  computed: {
    side() {
      return this.order?.side || this.order?.entry?.side
    },
    classes() {
      return [
        this.side === ORDER_SIDES.BUY ? 'badge-success' : 'badge-error',
        this.tooltip ? 'cursor-pointer' : 'select-none',
      ]
    },
    text() {
      return this.side === ORDER_SIDES.BUY ? 'Buy' : 'Sell'
    },
    tooltip() {
      return (this.order?.internalNote?.tooltip || '').replace(/\n/g, '<br/>')
    },
  },
  methods: {
    onClick() {
      this.order?.internalNote?.link &&
        window.open(this.order?.internalNote?.link, '_blank')
    },
  },
}
</script>
